import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Grid, InputAdornment, TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "flatpickr/dist/flatpickr.min.css";
import cardStyle from "../../theme/card-layout";
import "./calender.css";
import { useFormikContext } from "formik";
import dayjs from "dayjs";

// const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
//   return <input {...props} defaultValue={defaultValue} ref={inputRef} />;
// };

const CustomInput = ({ defaultValue, value, disable, inputRef, ...props }) => {
  return (
    <TextField
      disabled={disable}
      id="outlined-start-adornment"
      // sx={{ m: 1, width: "25ch", border: "none",  }}
      style={cardStyle}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarMonthIcon color="primary" />
          </InputAdornment>
        ),
      }}
      {...props}
      value={value} // Set the value prop to display the default value
      inputRef={inputRef}
    />
  );
};

export default function DateRangePicker({
  onDateRangeChange,
  start,
  end,
  stopDate,
}) {
  // const [selectedDate, setSelectedDate] = useState(null);
  // console.log("selectedDate", selectedDate);
  // const handleDateChange = (selectedDates) => {
  //   setSelectedDate(selectedDates);
  // };

  return (
    <Flatpickr
      options={{
        mode: "range",
        defaultDate: [start, end],
        dateFormat: "m-d-Y",
        maxDate: stopDate ? new Date() : null,
      }}
      onChange={onDateRangeChange}
      onReady={(selectedDates, dateStr, instance) => {
        const customInput = instance.input;

        customInput.placeholder = "Select date";
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return <CustomInput defaultValue={defaultValue} inputRef={ref} />;
      }}
    />
  );
}

export const DatePicker = ({
  setOnSetDate,
  onSetDate,
  fieldName,
  dateFrom,
  lastDate,
  addTask,
  disable = false,
}) => {
  // const [selectedDate, setSelectedDate] = useState(null);
  const dateRef = React.createRef(null);

  const formikContext = useFormikContext();

  useEffect(() => {
    if (!onSetDate) {
      console.log("first dateRef", dateRef?.current?.flatpickr?.setDate());
    }
  }, [onSetDate]);

  // Check if the context is available
  if (!formikContext) {
    console.error("DatePicker must be used within a Formik form");
    return null; // or render an alternative component or message
  }
  const { setFieldValue } = formikContext;

  console.log("selectedDate DatePicker", onSetDate);
  const handleDateChange = (selectedDates) => {
    console.log("@@@@", selectedDates);
    if (selectedDates[0]) {
      // let date = new Date(selectedDates[0]);
      if (setOnSetDate)
        // setOnSetDate(
        //   `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        // )
        setOnSetDate(dayjs(selectedDates[0]).format("YYYY-MM-DD"));

      if (fieldName && setFieldValue)
        // setFieldValue(fieldName, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
        setFieldValue(fieldName, dayjs(selectedDates[0]).format("YYYY-MM-DD"));
    } else {
      setOnSetDate("");
      setFieldValue(fieldName, "");
    }
  };

  return (
    <Flatpickr
      ref={dateRef}
      onReady={(selectedDates, dateStr, instance) => {
        const customInput = instance.input;

        customInput.placeholder = "Select date";
      }}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <CustomInput
            defaultValue={onSetDate}
            inputRef={ref}
            disable={disable}
            style={{ width: "100%" }}
          />
        );
      }}
      options={
        lastDate
          ? {
              dateFormat: "m-d-Y",
              defaultDate: [onSetDate ? new Date(onSetDate) : ""],
              maxDate: null,
              minDate: dateFrom ? new Date(dateFrom) : null,
            }
          : {
              dateFormat: "m-d-Y",
              defaultDate: [onSetDate ? new Date(onSetDate) : ""],
              maxDate: dateFrom ? new Date() : addTask ? "" : new Date(),
              minDate: dateFrom
                ? new Date(dateFrom)
                : addTask
                ? new Date()
                : null,
            }
      }
      onChange={handleDateChange}
    />
  );
};
